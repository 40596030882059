
/* @media screen and (max-width:767px) {
    .playstore{
        width: 20vw;
        background-color: red;
    }
} */

/* For Laptop: */

  /* .herobg {
    background-image: ("https://img.freepik.com/free-psd/buen-fin-template-design_23-2150804702.jpg") 
    
} */


/* Hide on pc */
@media screen and (min-width: 738px) {
    .mynavbar-mb{
        display:none;
    }

    .myhero-bg-mb{
        display:none
    }

    .eat-right-mb{
        display:none;
    }

    .features-mb{
        display:none
    }

    .getIntoShape-mb{
        display:none
    }


    .getaccess-mb{
        display:none
    }

    .ourmission-mb{
        display:none
    }

    .joinwaitlist-mb{
        display:none
    }

    .footer-mb{
        display:none
    }

    
}

/* Show on PC, Hide on Mobile: */
@media screen and (max-width: 737px) {
    .mynavbar{
        display:none;
    }
    
    .myhero-bg{
        display:none
    }

    .eat-right{
        display:none
    }

    .features{
        display:none
    }

    .getIntoShape{
        display:none
    }

    .getaccess{
        display:none
    }

    .ourmission{
        display:none
    }

    .joinwaitlist{
        display:none
    }
   
    .footer{
        display:none
    }

    .waitlistIndex{
        display:none
    }


}




/* ToggleSwitch.css */
.custom-control-label::before {
    background-color: #28a745; /* Green color for the "on" position */
  }


  
/* ToggleSwitch.css  2*/
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 30px;
    border-radius: 30px;
    background-color: #ccc;
    cursor: pointer;
  }
  
  .toggle-switch button {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    background-color: #00ff00;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .toggle-switch button:selected {
    transform: translateX(60px);
    background-color: #ffffff;
  }
  
  



